const CURRENCY_SYMBOLS = {
	GBP : '£'
};

export const courseCost = (course) => {
	if (!course.cost ||
			!course.currency) {
		return false;
	}
	if (course.isFree ||
			course.cost === '0') {
		return 'FREE';
	}
	const currencySymbol = (course.currency in CURRENCY_SYMBOLS) ? CURRENCY_SYMBOLS[course.currency] : '';
	return `${currencySymbol}${course.cost}`;
};

export const courseDiscountCost = (course) => {
	if (!course.costWithDiscount ||
		!course.currency) {
		return false;
	}
	if (course.isFree ||
		course.cost === '0') {
		return 'FREE';
	}
	const currencySymbol = (course.currency in CURRENCY_SYMBOLS) ? CURRENCY_SYMBOLS[course.currency] : '';
	return `${currencySymbol}${course.costWithDiscount}`;
};
