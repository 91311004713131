<template>
	<component
		data-component="course-card"
		:is="getComponent"
		v-bind="getProps"
		:data-enrolled="getIsEnrolled"
		:data-level="course.minExperienceLevel"
		@click="click"
	>
		<live-or-solo-badge
			:isLive="getIsLive"
			courseOrLesson="course"
		/>
		<p
			data-element="teacher"
		>
			<icon
				icon="courses"
			/>
			<span>
				{{ getTeacherName }}
			</span>
		</p>
		<h1 data-element="title">
			{{ course.title }}
		</h1>
		<p data-element="description">
			{{ course.description }}
		</p>
		<p data-element="lessons">
			{{ getLessonsString }}
		</p>
		<p data-element="bidding-systems">
			{{ getBiddingSystemsString }}
		</p>
		<experience-level-chart
			:minExperienceLevel="course.minExperienceLevel"
			:maxExperienceLevel="course.maxExperienceLevel"
		/>
		<p
			v-if="getDiscountCost"
			data-element="cost"
			data-type="discount-cost"
		>
			<span data-element="initial-cost">{{ getCost }}</span> {{ getDiscountCost }}
		</p>
		<p
			v-else-if="getCost"
			data-element="cost"
		>
			{{ getCost }}
		</p>
		<p
			v-if="getIsFree"
			data-element="free"
		>
			<span data-element="text">
				FREE
			</span>
		</p>
		<div
			data-element="status-info"
			v-if="getStatusInfoString"
		>
			{{ getStatusInfoString }}
		</div>
	</component>
</template>

<script>

	import LiveOrSoloBadge       from '@/components/ui/LiveOrSoloBadge';
	import Icon                  from '@/components/ui/Icon';
	import ExperienceLevelChart  from '@/components/courses/ExperienceLevelChart';
	import action                from '@/mixins/action';
	import routeParams           from '@/mixins/routeParams';
	import { courseCost, courseDiscountCost } from '@/formatters';
	// import dayjs                 from 'dayjs';
	// import relativeTime          from 'dayjs/plugin/relativeTime';
	// dayjs.extend(relativeTime);

	export default {
		components: {
			LiveOrSoloBadge,
			Icon,
			ExperienceLevelChart
		},
		mixins: [action, routeParams],
		props: {
			route: {
				type: String,
				default: undefined
			},
			course: {
				type: Object,
				default: undefined
			}
		},
		computed: {
			getIsEnrolled () {
				if (this.getContext !== 'browseCourses') {
					return false;
				}
				if (this.course?.activated) {
					return true;
				}
				return false;
			},
			getColour () {
				if (this.getContext !== 'browseCourses') {
					return false;
				}
				if (this.course?.activated) {
					return true;
				}
				return false;
			},
			getIsLive () {
				return this.course.live;
			},
			getTeacherName () {
				return this.course?.teacherName;
			},
			getLessonsString () {
				return `Lessons: ${this.course.lessonCount}`;
			},
			getBiddingSystemsString () {
				if (this.course?.biddingSystems !== null &&
						!Array.isArray(this.course.biddingSystems)) {
					return false;
				}
				if (this.course.biddingSystems === null) {
					return 'Bidding systems: None';
				}
				if (!this.course.biddingSystems?.length) {
					return 'Bidding systems: Any';
				}
				return `Bidding systems: ${this.course.biddingSystems.join(', ')}`;
			},
			getIsFree () {
				return this.course?.isFree === true;
			},
			getCost () {
				if (this.course.isFree) {
					return false;
				}
				return courseCost(this.course);
			},
			getDiscountCost () {
				if (this.course.isFree || !this.course?.costWithDiscount) {
					return false;
				}
				return courseDiscountCost(this.course);
			},
			getIsArchived () {
				return this.course?.status === 'archived';
			},
			getIsDraft () {
				return this.course?.status === 'draft';
			},
			getIsDeleted () {
				return this.course?.status === 'deleted';
			},
			getIsComplete () {
				if (this.course?.live) {
					return this.course?.isComplete;
				}
				if (this.getContext !== 'myCourses') {
					return false;
				}
				return this.course.progress !== null;
			},
			getStatusInfoString () {
				if (!this.getIsEnrolled &&
						!this.getIsDeleted &&
						!this.getIsDraft &&
						!this.getIsArchived &&
						!this.getIsComplete) {
					return false;
				}
				const statuses = [
					(this.getIsEnrolled ? 'Already enrolled' : false),
					(this.getIsDeleted ? 'Deleted' : false),
					(this.getIsDraft ? 'Draft' : false),
					(this.getIsArchived ? 'Archived' : false),
					(this.getIsComplete ? 'Complete' : false)
				].filter(Boolean);
				return statuses.join(', ');
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='course-card'] {
		position:relative;
		display:flex;
		flex-direction:column;
		padding:rem(20) rem(18) rem(52);
		border:6px solid $c-brand-blue;
		border-radius:8px;
		&[data-enrolled] {
			opacity:0.5;
		}
		&[data-level='1'],
		&[data-level='2'] {
			background-color:transparentize($c-experience-levels-beginner-blue, 0.9);
		}
		&[data-level='3'],
		&[data-level='4'] {
			background-color:transparentize($c-experience-levels-improver-green, 0.9);
		}
		&[data-level='5'],
		&[data-level='6'] {
			background-color:transparentize($c-experience-levels-intermediate-red, 0.9);
		}
		&[data-level='7'],
		&[data-level='8'] {
			background-color:transparentize($c-experience-levels-advanced-purple, 0.8);
		}
		[data-component='live-or-solo-badge'] {
			margin:0 auto rem(18) 0;
		}
		[data-element='teacher'] {
			display:flex;
			align-items:center;
			margin:0 0 rem(16);
			[data-component='icon'] {
				width:auto;
				height:rem(38);
				margin:0 rem(4) 0 0;
			}
			span {
				font-family:'Montserrat', sans-serif;
				font-size:rem(14);
				font-weight:700;
				color:$c-brand-grey;
			}
		}
		[data-element='title'] {
			margin:0 60px rem(8) 0;
			font-family:'Montserrat', sans-serif;
			font-weight:700;
			font-size:rem(22);
		}
		[data-element='description'] {
			margin:0 rem(60) auto 0;
			font-size:rem(18);
		}
		[data-element='lessons'] {
			margin:rem(20) 0 rem(2) 0;
			font-size:rem(16);
			color:$c-brand-blue;
		}
		[data-element='bidding-systems'] {
			margin:0 0 0 0;
			font-size:rem(16);
			color:$c-brand-blue;
		}
		[data-component='experience-level-chart'] {
			position:absolute;
			bottom:rem(14);
			left:rem(18);
		}
		[data-element='cost'] {
			position:absolute;
			right:rem(14);
			bottom:rem(14);
			display:flex;
			align-items:center;
			justify-content:center;
			height:rem(22);
			padding:0 rem(6);
			font-family:'Montserrat', sans-serif;
			font-size:rem(14);
			line-height:1;
			font-weight:700;
			color:$c-white;
			background-color:$c-brand-blue;
			border-radius:4px;
		}
		[data-element='initial-cost'] {
			display:flex;
			margin-right:rem(6);
			font-family:'Montserrat', sans-serif;
			font-size:rem(12);
			line-height:1;
			font-weight:600;
			color:$c-white;
			text-decoration:line-through;
		}
		[data-type='discount-cost'] {
			background-color:$c-brand-green !important;
		}
		[data-element='free'] {
			position:absolute;
			top:-4px; right:-4px;
			display:flex;
			justify-content:center;
			align-items:center;
			height:60px;
			color:$c-white;
			transform:translate3d(50%,0,0)
				rotate(45deg);
			transform-origin:top center;
			[data-element='text'] {
				margin-top:1.8em;
				font-family:'Montserrat', sans-serif;
				font-weight:700;
				font-size:rem(14);
			}
			&:before {
				content:'';
				position:absolute;
				z-index:-1;
				top:0; left:50%;
				display:block;
				width: 0;
				height: 0;
				border-width:0 60px 60px 60px;
				border-top-color:transparent;
				border-right-color:transparent;
				border-bottom-color:$c-brand-blue;
				border-left-color:transparent;
				border-style:solid;
				transform:translate3d(-50%,0,0);
			}
		}
		[data-element='status-info'] {
			position:absolute;
			right:0;
			bottom:-4px;
			left:0;
			padding:0 rem(18);
			font-size:rem(12);
			font-weight:700;
			color:$c-white;
			background-color:$c-brand-blue;
		}
	}

</style>
